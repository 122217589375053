<template>
   <div>
      <h2>请依次选择下面量表进行评估,量表名右上方带小红点是必选项</h2>
      <h4>
         (注1：前四项只需要录入验证一次患者，后面的所有评估每次做完都还需要验证患者信息)
      </h4>
      <h4>
         (注2：如果提交按钮是浅色的说明，您还有题没有做完，请做完才会正常提交)
      </h4>
      <div id="box">
         <el-badge is-dot
                   class="item">
            <el-button type="success"
                       @click="toMEQ">1：睡眠觉醒MEQ</el-button>
         </el-badge>
         <el-badge is-dot
                   class="item">
            <el-button type="success"
                       @click="toHAMD">2：抑郁量表HAMD</el-button>
         </el-badge>
         <el-badge is-dot
                   class="item">
            <el-button type="success"
                       @click="toBAI">3：焦虑量表BAI</el-button>
         </el-badge>
         <el-badge is-dot
                   class="item">
            <el-button type="success"
                       @click="toPSQI">4：睡眠质量量表PSQI</el-button>
         </el-badge>
         <el-badge class="item">
            <el-button type="success"
                       @click="toPHQ">5：抑郁症筛查量表PHQ</el-button>
         </el-badge>
         <el-badge class="item">
            <el-button type="success"
                       @click="toEPDS">6：产后抑郁量表EPDS</el-button>
         </el-badge>
         <el-badge class="item">
            <el-button type="success"
                       @click="toGDS">7：老年抑郁量表GDS</el-button>
         </el-badge>
         <el-badge class="item">
            <el-button type="success"
                       @click="toESS"> 8:嗜睡量表ESS</el-button>
         </el-badge>
         <el-badge class="item">
            <el-button type="success"
                       @click="toQOL">
               9:生活质量量表QOL-AD</el-button>
         </el-badge>
         <el-badge class="item">
            <el-button type="success"
                       @click="toRPQ">
               10:脑震荡后综合征量表RPQ</el-button>
         </el-badge>
         <el-badge class="item">
            <el-button type="success"
                       @click="toCMAI"
                       id="goSystem">
               11:躁动行为量表CMAI</el-button>
         </el-badge>
      </div>
      <el-button type="primary"
                 @click="goSystem">回到系统</el-button>
   </div>
</template>

<script>
export default {
   methods: {
      toMEQ () {
         this.$router.push("/MEQ");
      },
      toHAMD () {
         this.$router.push("/HAMD");
      },
      toPHQ () {
         this.$router.push("/PHQ");
      },
      toEPDS () {
         this.$router.push("/EPDS");
      },
      toGDS () {
         this.$router.push("/GDS");
      },
      toBAI () {
         this.$router.push("/BAI");
      },
      toESS () {
         this.$router.push("/ESS");
      },
      toQOL () {
         this.$router.push("/QOL");
      },
      toRPQ () {
         this.$router.push("/RPQ");
      },
      toCMAI () {
         this.$router.push("/CMAI");
      },
      toPSQI () {
         this.$router.push("/PSQI");
      },
      goSystem () {
         this.$router.push("/System/AssessmentHome");
         // 清空本地存储
         localStorage.removeItem("patientN");
         localStorage.removeItem("patientI");
         localStorage.removeItem("MEQ");
         localStorage.removeItem("BAI");
         localStorage.removeItem("HAMD");
      },
   },
};
</script>

<style lang="scss" scoped>
#box {
   display: flex;
   flex-wrap: wrap;
   width: 80%;
   margin: auto;
   .el-button {
      width: 100%;
      height: 100%;
   }
   div {
      justify-items: center;
      margin: 20px;
      width: 300px;
      height: 50px;
      background: rgb(113, 215, 233);
      text-align: center;
      line-height: 50px;
   }
}
h4 {
   color: red;
}
.goSystem {
   width: 70px;
}
</style>
